body {
  padding: 0;
  margin: 0;
  font-size: 1rem;
  background-color: #c9c3bc!important; 
  color: #000000;
  scroll-behavior: smooth;
  overflow-x: hidden;   
  font-family: 'AktivGrotesk-Regular', sans-serif!important; 
}

/* Font Styles */
@font-face {
  font-family: 'AktivGrotesk-Bold';
  src: url('./fonts/AktivGrotesk-Bold.otf');
}
@font-face {
  font-family: 'AktivGrotesk-Regular';
  src: url('./fonts/AktivGrotesk-Regular.otf');
}
@font-face {
  font-family: 'Felis-Black';
  src: url('./fonts/Felis-Black.ttf');
}  
@font-face {
  font-family: 'Felis-Bold';
  src: url('./fonts/Felis-Bold.ttf');
} 
@font-face {
  font-family: 'Felis-Regular';
  src: url('./fonts/Felis-Regular.ttf');
} 
 
@font-face {
  font-family: 'Felis-SemiBold';
  src: url('./fonts/Felis-SemiBold.ttf');
} 
 
.aktivGroteskRegular {
  font-family: 'AktivGrotesk-Regular';
  src: url('./fonts/AktivGrotesk-Regular.otf');
}
.aktivGroteskBold {
  font-family: 'AktivGrotesk-Bold';
  src: url('./fonts/AktivGrotesk-Bold.otf');
}

.felisBlack {
  font-family: 'Felis-Black';
  src: url('./fonts/Felis-Black.ttf');
}

.felisBold {
  font-family: 'Felis-Bold';
  src: url('./fonts/Felis-Bold.ttf');
}

.felisRegular {
  font-family: 'Felis-Regular';
  src: url('./fonts/Felis-Regular.ttf');
}

.felisSemiBold {
  font-family: 'Felis-SemiBold';
  src: url('./fonts/Felis-SemiBold.ttf');
}